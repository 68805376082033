import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Zac = () => (
  <Layout>
    <SEO title="Zac" />
    <h1>Hi I'm Zac</h1>
    <p>I'm an easy going and curious super friendly little dude. Adopted on April 17, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/zac.jpg" alt="Zac" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Zac
